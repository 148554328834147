<template>
  <div class="d-flex align-items-center mr-4 w-100">
    <b-form-group :label="translations.filters.client_id" class="flex-grow-1 mr-4">
      <b-form-select v-model="filter_set.client_id"
        :disabled="loading"
        :options="filter_options.clients"></b-form-select>
    </b-form-group>
    <b-form-group :label="translations.filters.created_at" class="flex-grow-1 mr-4">
      <vue-ctk-date-time-picker format="YYYY-MM-DD"
        :label="translations.filters.select_date"
        formatted="ll"
        range
        :error="!dateRangeValidator()"
        only-date
        v-model="filter_set.date"/>
    </b-form-group>
    <b-form-group :label="translations.filters.account_uuid" class="flex-grow-1 mr-4">
      <b-form-input
        id="account-uuid-filter"
        type="text"
        v-model="filter_set.account_uuid"
        :state="uuidValidatorFormState(filter_set.account_uuid)"></b-form-input>
    </b-form-group>
    <b-form-group :label="translations.filters.program_uuid" class="flex-grow-1 mr-4">
      <b-form-input
        id="program-uuid-filter"
        type="text"
        v-model="filter_set.program_uuid"
        :state="uuidValidatorFormState(filter_set.program_uuid)"></b-form-input>
    </b-form-group>
    <b-form-group :label="translations.filters.program_id" class="flex-grow-1 mr-4">
      <b-form-input
        id="patient-id-filter"
        type="number"
        v-model="filter_set.patient_id"
        :state="getPatientIdFormState()"></b-form-input>
    </b-form-group>
    <b-button
      variant="primary"
      class="mt-2"
      @click="applyFilters"
      :disabled="!isFormValid()">
      <feather type="search"></feather>
    </b-button>
  </div>
</template>

<script>
import translations from '@/translations';
import { validate as uuidValidator } from 'uuid';

export default {
  name: 'TransactionFilters',
  data() {
    return {
      translations: translations.finance.transactions,
      filter_set: {
        client_id: null,
        date: null,
        patient_id: null,
        program_uuid: null,
        account_uuid: null,
      },
      filter_options: {
        clients: [
          { value: '', text: translations.finance.transactions.filters.default_client_filter },
        ],
      },
    };
  },
  beforeMount() {
    this.loadFilters();
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    applyFilters() {
      const filters = {
        client_id: this.filter_set.client_id,
        patient_id: this.filter_set.patient_id && Number.parseInt(this.filter_set.patient_id, 10),
        program_uuid: this.filter_set.program_uuid,
        account_uuid: this.filter_set.account_uuid,
      };

      if (this.filter_set.date) {
        filters.event_date_start = this.filter_set.date.start;
        filters.event_date_end = this.filter_set.date.end;
      }
      this.$emit('apply-filters', filters);
    },
    async loadFilters() {
      try {
        const res = await this.$store.dispatch('Financial/getFilters');
        if (!res) {
          return;
        }

        const responseFilters = res.data
          .map(({ id: value, display_name: displayName }) => ({ value, text: `${displayName} (ID: ${value})` }));

        this.filter_options.clients = [ ...this.filter_options.clients, ...responseFilters ];
      } catch (_) {
        this.$noty.error(this.translations.errors.loading_filters);
      }
    },
    dateRangeValidator() {
      if (!this.filter_set.date) {
        return true;
      }
      const { start, end } = this.filter_set.date;
      return start && end;
    },
    patientIdValidator() {
      if (!this.filter_set.patient_id) {
        return true;
      }

      const patientId = +this.filter_set.patient_id;
      return patientId > 0 && Number.isInteger(patientId);
    },
    getPatientIdFormState() {
      return this.patientIdValidator() && undefined;
    },
    isValidUUID(field) {
      if (!field) {
        return true;
      }

      return uuidValidator(field);
    },
    uuidValidatorFormState(field) {
      return this.isValidUUID(field) && undefined;
    },
    isFormValid() {
      const validations = [
        this.dateRangeValidator,
        this.patientIdValidator,
      ];

      return validations.reduce((prev, curr) => prev && curr(), true);
    },
  },
};
</script>

<style lang="scss" scoped>
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
</style>
