<template>
  <div class="mt-4 container-fluid">
    <div class="d-flex justify-content-between mb-4">
      <h3>{{ translations.list_page_title }}</h3>
    </div>

    <div class="d-flex align-items-center justify-content-between">
      <transaction-filters :loading="loadingPage" @apply-filters="applyFilters" />
      <export-transactions-button :filters="filters" filename="transactions_list" />
    </div>

    <financial-list
      v-model="currentPage"
      :header-data="TABLE_HEADERS"
      :data="transactions"
      :disabled="loadingPage"
      :items-per-page="itemsPerPage"
      @page-changed="loadTransactionsList"
      :total="total"
      :no-items-message="translations.missing_transactions"/>

  </div>
</template>

<script>
import translations from '@/translations';
import { formatDatetime, formatValue } from '@/helpers/finance';
import FinancialList, { associateHeaderDataItem } from '@/components/Financial/FinancialList.vue';
import ExportTransactionsButton from '@/components/Financial/ExportTransactionsButton.vue';
import TransactionFilters from './TransactionFilter.vue';

export default {
  components: {
    FinancialList,
    TransactionFilters,
    ExportTransactionsButton,
  },
  name: 'FinanceTransactionsList.vue',
  created() {
    this.TABLE_HEADERS = [
      associateHeaderDataItem(this.translations.transaction_id, 'id'),
      associateHeaderDataItem(this.translations.created_at, 'created_at'),
      associateHeaderDataItem(this.translations.client_name, 'client_name'),
      associateHeaderDataItem(this.translations.account_uuid, 'account_uuid'),
      associateHeaderDataItem(this.translations.program_uuid, 'program_uuid'),
      associateHeaderDataItem(this.translations.program_id, 'patient_id'),
      associateHeaderDataItem(this.translations.patient_name, 'member_full_name'),
      associateHeaderDataItem(this.translations.unit, 'unit'),
      associateHeaderDataItem(this.translations.therapy, 'therapy_name'),
      associateHeaderDataItem(this.translations.transaction_type, 'transaction_type'),
      associateHeaderDataItem(this.translations.value, 'transaction_value'),
    ];
  },
  data() {
    return {
      translations: translations.finance.transactions,
      transactions: [],
      loadingPage: true,
      itemsPerPage: 10,
      currentPage: 1,
      total: 0,
      appliedFilters: {},
    };
  },
  computed: {
    filters: {
      get() {
        return {
          ...this.appliedFilters,
          offset: (this.currentPage - 1) * this.itemsPerPage,
          limit: this.itemsPerPage,
          order_by: 'id',
        };
      },
    },
  },
  async beforeMount() {
    await this.loadTransactionsList();
  },
  methods: {
    applyFilters(filters) {
      this.currentPage = 1;
      this.appliedFilters = filters;
      this.loadTransactionsList();
    },
    async loadTransactionsList() {
      this.loadingPage = true;
      const searchFilters = { ...this.filters };
      searchFilters.order_descending = true;
      const apiTransactions = await this.$store.dispatch('Financial/getTransactions', searchFilters);

      if (!apiTransactions) {
        this.total = 0;
        this.transactions = [];
      } else {
        this.total = apiTransactions.total;
        this.transactions = apiTransactions.transactions;
      }

      this.transactions.forEach(transaction => {
        transaction.created_at = formatDatetime(transaction.event_date);
        transaction.transaction_value = formatValue(transaction.transaction_value);
      });
      this.loadingPage = false;
    },
  },
};
</script>
